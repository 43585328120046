import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegulationNotesService {

  private path = environment.API_BASE_URL_REGULATION_AUTO + '/regulationautonote';

  constructor(
    private http: HttpClient) { }

  /**
   * 
   * @param id 
   * @param noteType 
   * @returns {Observable<any>}
   * @memberof RegulationNotesService
   */
  getAll(id: string, noteType: string): Observable<any> {
    return this.http.get(`${this.path}/regulationauto/${id}/notetype/${noteType}`);
  }

  /**
   * 
   * @param data 
   * @returns {Observable<any>}
   * @memberof RegulationNotesService
   */
  save(data: any): Observable<any> {
    return this.http.post(`${this.path}`, data);
  }
}
