import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NoteListComponent } from './../note-list.component';

@Injectable()
export class NoteListService {

  constructor(private modalService: NgbModal) { }

  public open(
    regulationAutoId: string,
    noteType: string,
    dialogSize: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' = 'lg',
    dialogCentered: true | false = true): Promise<boolean> {
    const modalRef = this.modalService.open(NoteListComponent, { size: dialogSize, centered: dialogCentered });
    modalRef.componentInstance.regulationAutoId = regulationAutoId;
    modalRef.componentInstance.noteType = noteType;
    return modalRef.result;
  }
}
