<div class="modal-header">
  <h5 class="modal-title">
    <svg cIcon name="cilNotes" size="xl"></svg>
    Notas
  </h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="dismiss()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row mb-2">
      <div class="col-12">
        <label cLabel for="note">Nota:</label>
        <div class="form-check form-switch form-check-reverse float-end px-0">
          <button cButton color="primary" variant="ghost" cTooltip="Clique para mencionar" (click)="openMention()">
            <svg cIcon size="xl" name="cilChevronTopAlt" class="pt-1" *ngIf="showMention"></svg>
            <svg cIcon size="xl" name="cilChevronBottomAlt" class="pt-1" *ngIf="!showMention"></svg>
            Mencionar
          </button>
        </div>
        <textarea class="form-control" [ngClass]="{'is-invalid': errorMessage(note) !== null}" formControlName="note" id="note" rows="2"
          placeholder="Digite uma anotação"></textarea>
        <div *ngIf="errorMessage(note) !== null" class="invalid-feedback">
          {{ errorMessage(note) }}
        </div>
      </div>
    </div>
    <div class="row mb-2" *ngIf="showMention">
      <div class="col-lg-12">
        <label cLabel for="users">Usuário:</label>
        <c-multi-select id="users" #users multiple optionsStyle="text" placeholder="Selecione..."
          searchNoResultsLabel="Oops! Elemento não encontrado." selectAllLabel="Selecionar todos"
          formControlName="users" optionsMaxHeight="600">
          <c-multi-select-option *ngFor="let accountItem of accountList" [value]="accountItem.value">{{
            accountItem.label
            }}</c-multi-select-option>
        </c-multi-select>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-grid  d-md-flex flex-row justify-content-md-end">
        <button class="btn btn-primary me-2" (click)="onClean()" cTooltip="Clique para limpar o formulário">
          <svg cIcon name="cilXCircle"></svg>
          Limpar
        </button>    
        <button cButton color="primary" type="submit" (click)="onSave()" [loading]="loading" cLoadingButton [disabled]="form.invalid"
          cTooltip="Clique para gravar">
          <svg cIcon name="cilCheckAlt" *ngIf="!loading"></svg>
          Gravar
        </button>
      </div>
    </div>
  </form>    
  <hr>

  <div class="list-group">    
    <a class="list-group-item list-group-item-action" *ngFor="let noteItem of noteList">     
      <p class="mb-1">{{ noteItem?.note }}</p>
      <div class="d-flex w-100 justify-content-between">
        <small class="text-muted mb-1">{{ noteItem?.accountName }}</small>
        <small class="text-muted">{{ noteItem?.creationDate | data }}</small>
      </div>      
    </a>
  </div>

</div>