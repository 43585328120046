import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidationService } from 'src/app/shared/service/validation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegulationNotesService } from 'src/app/shared/service/regulation-notes.service'
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ItemSelect } from '../../models/item-select.model';
import { AccountService } from '../../service/account.service';
import { UtilService } from '../../service/util.service';
import { MultiSelectComponent } from '@coreui/angular-pro';

@Component({
  selector: 'note-list',
  templateUrl: './note-list.component.html',
  styleUrls: ['./note-list.component.scss'],
  providers: [ RegulationNotesService ]
})
export class NoteListComponent implements OnInit{

  @Input() regulationAutoId : string = "";

  @Input() noteType : string = "";

  @ViewChild('users') userMulti:  MultiSelectComponent | undefined;

  public noteList     : any = [];
  public accountList  : Array<ItemSelect> = [];
  public loading      : boolean = false;
  public showMention  : boolean = false;

  //FORM GROUP
  public form !: FormGroup;
  //FORM
  public note  !: AbstractControl;
  public users !: AbstractControl;
 
  constructor(
    private validationService       : ValidationService,
    private activeModal             : NgbActiveModal,
    private formBuilder             : FormBuilder,
    private spinnerService          : NgxSpinnerService,
    private toastr                  : ToastrService,
    private regulationNotesService  : RegulationNotesService,
    private accountService          : AccountService,
    private utilService             : UtilService,
  ) {}

  ngOnInit() {
    this.onLoad();
    this.buildForm();
  }

  onLoad() {
    this.spinnerService.show();
    this.regulationNotesService.getAll(this.regulationAutoId, this.noteType)
      .subscribe({ 
        next:async (response: any) => {
          if (response.code == 0) {
              this.noteList = response.data;
          }
          else {
            this.toastr.error("Ocorreu uma falha ao buscar notas: [" + response?.resultException?.Message + "].", "Erro");
          }
          this.spinnerService.hide();
        }, 
        error: (erro: HttpErrorResponse) => { 
          this.toastr.error("Ocorreu uma falha ao buscar notas: [" + erro?.message?.replace(environment.API_BASE_URL_REGULATION_AUTO, '') + "].", "Erro")
          this.spinnerService.hide();
        }
      });
  }

  /**
     * Montagem do formulário
     * @memberof EmailDialogComponent
     */
  buildForm(): void {    
    this.form = this.formBuilder.group({
      note  : ['', Validators.compose([Validators.maxLength(1000)])],
      users : ['', Validators.compose([])]
    });

    this.note  = this.form.controls['note'];
    this.users = this.form.controls['users'];
  }

  public decline() {
    this.activeModal.close();
  }

  public onSave() {
    let dataSend = {
      "id": "00000000-0000-0000-0000-000000000000",
      "regulationautoid": this.regulationAutoId,
      "note": this.note.getRawValue(),
      "notifierAccountsIds": this.users.getRawValue(),
      "notetype": this.noteType
    }; 

    this.loading = true;
    this.regulationNotesService.save(dataSend)
      .subscribe({ 
        next:async (response: any) => {
          if (response.code == 0) {
            this.toastr.success("Sucesso ao adicionar nota.", "Sucesso");
          }
          else {
            this.toastr.error("Ocorreu uma falha ao gravar comentário no Sinistro: [" + response?.resultException?.Message + "].", "Erro");
          }
          this.loading = false;
        }, 
        error: (erro: HttpErrorResponse) => { 
          this.toastr.error("Ocorreu uma falha ao gravar comentário no Sinistro: [" + erro?.message?.replace(environment.API_BASE_URL_REGULATION_AUTO, '') + "].", "Erro")
          this.loading = false;
        }
      });
  }

  openMention() {
    this.showMention = !this.showMention;

    if (this.showMention && this.accountList?.length == 0) {
      this.spinnerService.show();
      this.accountService.getAllAccount()
      .subscribe({  
        next: (data) => { 
          this.accountList = this.utilService.getAllLabelValueSelectedValues('name', 'id', data.data, 'id', []); 
          this.spinnerService.hide();
        },  
        error: (erro: HttpErrorResponse) => {  
          this.toastr.error(erro.statusText, "Erro")
          this.spinnerService.hide();
        }
      });
    }
  }

  onClean() {
    this.form.reset();
    this.userMulti?.clearAllOptions();
  }

  /**
   * 
   */
  public dismiss() {
    this.activeModal.dismiss();
  }

  /**
   * 
   * @param {AbstractControl} control
   * @returns 
   */
  errorMessage(control: AbstractControl) {
    return this.validationService.errorMessage(control);
  }
}