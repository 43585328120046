<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true">
    <button
      toggle="visible"
      cHeaderToggler
      [cSidebarToggle]="sidebarId"
      class="ps-1"
    >
      <svg cIcon
        name="cilMenu"
        size="lg"
      ></svg>
    </button>
    <c-header-nav class="ms-auto me-4">
      <form [formGroup]="themeSwitch" cForm>
        <c-button-group aria-label="Theme switch">
          <input
            class="btn-check"
            formControlName="themeSwitchRadio"
            id="btn-light-theme"
            type="radio"
            value="light"
          />
          <label
            (click)="setTheme('light')"
            cButton
            cFormCheckLabel
            color="primary"
          >
            <svg cIcon name="cilSun"></svg>
          </label>
          <input
            class="btn-check"
            formControlName="themeSwitchRadio"
            id="btn-dark-theme"
            type="radio"
            value="dark"
          />
          <label
            (click)="setTheme('dark')"
            cButton
            cFormCheckLabel
            color="primary"
          >
            <svg cIcon name="cilMoon"></svg>
          </label>
        </c-button-group>
      </form>
    </c-header-nav>

    <!-- todo: header nav dropdown menus  -->
    <c-header-nav class="ms-3 me-sm-4">
      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
      <ng-container *ngTemplateOutlet="messagesDropdown"></ng-container>
    </c-header-nav>
    <!--aside toggler-->
    <button
      toggle="visible"
      cHeaderToggler
      cSidebarToggle="aside1"
      class="px-md-0 me-md-3 d-none d-sm-block"
    >
      <svg cIcon name="cilApplicationsSettings" size="lg"></svg>
    </button>
  </c-container>
  <c-header-divider></c-header-divider>
  <c-container [fluid]="true">
    <c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
  </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button
      cButton
      color=""
      [caret]="false"
      cDropdownToggle
      class="py-0"
    >
      <c-avatar *ngIf="userLogged?.imageBase64; else avatarOut"
        cTextColor="primary"
        shape="rounded-1"
        size="md"
        [src]="getDataImageBase64()"
      >
      </c-avatar>
      <ng-template #avatarOut>
        <c-avatar color="secondary">{{ iniciaisNome }}</c-avatar>
      </ng-template>
      {{ userLogged?.name }}
    </button>
    <ul cDropdownMenu class="pt-0 pr-5 w-auto">
      <li>
        <h6 cDropdownHeader class="fw-semibold py-2">Conta</h6>
      </li>
      <li>
        <a routerLink="/administracao/perfil" cDropdownItem>
          <svg cIcon class="me-2" name="cilUser"></svg>
          Perfil
        </a>
      </li>
      <li *ngIf="userLogged?.expertID">
        <a routerLink="/pendencias" cDropdownItem>
          <svg cIcon class="me-2" name="cilWarning"></svg>
          Pendências
        </a>
      </li>
      <li>
        <a routerLink="/login" cDropdownItem (click)="defaultTheme()">
          <svg cIcon class="me-2" name="cilAccountLogout"></svg>
          Sair
        </a>
      </li>
    </ul>
  </c-dropdown>
</ng-template>

<ng-template #messagesDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <a [caret]="false" cDropdownToggle href>
      <svg cIcon class="my-1 mx-2" name="cilBell" size="lg"></svg>
      <c-badge
        *ngIf="newMessages?.length"
        class="position-absolute top-0 end-0"
        color="info-gradient"
        shape="rounded-pill"
      >
        {{ newMessages?.length ?? 0 }}
      </c-badge>
    </a>
    <div cDropdownMenu class="pt-0" style="max-width: 50rem;">
      <h6 cDropdownHeader class="bg-light dark:bg-white dark:bg-opacity-10">
        <strong>Você tem {{ newMessages?.length ?? 0 }} mensagens</strong>
      </h6>
      
      <a cDropdownItem *ngFor="let msg of newMessages;">
        <div class="message" (click)="setStatusMenssage(msg)" style="cursor:pointer;">
          <div class="row">
            <div class="col-12">
              <small class="text-medium-emphasis ">{{ msg?.title }}</small>
            <small class="text-medium-emphasis float-end mt-1">{{ msg?.notifierDate | data}}</small>
          </div>            
          </div>
          <p class="font-weight-bold" style="overflow: hidden; text-overflow: ellipsis;">
            {{ msg?.description }}
          </p>
        </div>
      </a>
      <a
        cDropdownItem
        class="text-center border-top"
        routerLink="notificacao/mensagens"
      >
        <strong>Ver todas as mensagens</strong>
      </a>
    </div>
  </c-dropdown>
</ng-template>